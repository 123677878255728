<template>
  <div class="content-container">
    <h1>Booking Inquiries</h1>
    <p>Affinity Strings provides experienced professional musicians, with a wide variety of ensemble options, for just about any occasion. All musicians affiliated with Affinty Strings are experienced professionals who will add a polished touch of class to your special occasion.</p>
    
    <img src="@/assets/quartet.jpg" alt="quartet">

    <hr>

    <div class="columns-container">
      <div class="column">
        <h2>Occasions</h2>
        <ul>
          <li>Wedding Ceremonies</li>
          <li>Anniversary Celebrations</li>
          <li>Graduation Parties</li>
          <li>Baby Showers</li>
          <li>Receptions & Banquets</li>
          <li>Worship Services</li>
          <li>Funeral/Memorial Services</li>
          <li><strong>Any Special Events</strong></li>
        </ul>
      </div>
      <div class="column">
        <h2>Ensembles</h2> 
        <ul>
          <li>String Quartet (The Classic String Ensemble)</li>
          <li>String Trio or Duet (For a great string sound on a budget)</li>
          <li>String Chamber Orchestra (Fill the hall with music for a large occasion)</li>
          <li>Trumpet with a String Quartet</li>
          <li>Harp & Violin Duet</li>
          <li>Flute with Strings</li>
          <li><strong>We can customize ensembles for you (just ask)</strong></li>
        </ul>
      </div>
    </div>

    <hr>

    <h1>Music</h1>
    <p>Some people know exactly what music they want to have performed at their event while others need some help deciding what would be most appropriate. Either way, we will make the music happen so that it is profesional sounding and taylored to your event.</p>
    <p>A string quartet (consisting of two violins, a violist, and a cellist) is the standard ensemble size that represents the fullest range of sound and the largest available music literature for a chamber ensemble.</p>

    <h2>Quartets</h2>

    <p>A traditional wedding with a quartet, for example, might include J.S Bach’s Air on G for a wedding party processional:</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/bach_air.mp3">
    </audio>
    <p>J.S. Bach’s Air on G</p>

    <p>Pachelbel’s for the entrance of the bride:</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/Quartet_Pachelbel_Canon.mp3">
    </audio>
    <p>Pachelbel’s famous Canon in D</p>

    <p>A special contemporary song like Here, There, and Everywhere during the ceremony (sometimes lighting of the candles):</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/Quartet_Here_There_Everywhere.mp3">
    </audio>
    <p>Here, There, and Everywhere</p>

    <p>G.F. Handel’s Hornpipe for a recessional:</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/Quartet_Hornpipe.mp3">
    </audio>
    <p>G.F. Handel’s Hornpipe</p>

    <p>and it is customary for the ensemble to play a variety of music 15-20 minutes before the wedding and after the wedding as the guests are leaving:</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/Quartet_Beguine_Beginning.mp3">
    </audio>
    <p>Begin the Beguine</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/Quartet_Beguine_Ending.mp3">
    </audio>
    <p>Ending the Beguine</p>

    <h2>Duos and Trios</h2>
    <p>Duets and trios are smaller ensembles that do not have the full range of a string quartet, but can still provide an elegant touch to your event. Here are some traditional wedding excerpts arranged for a violin and viola duet:</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/Duet_Jesu_Joy.mp3">
    </audio>
    <p>Jesu Joy of Mans Desiring</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/Duet_Trumpet_Voluntary.mp3">
    </audio>
    <p>Trumpet Voluntary</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/Duet_Trumpet_Tune.mp3">
    </audio>
    <p>Trumpet Tune</p>
    <p>or even Darth Vader’s March (yes, this was the recessional at this particular wedding – we are flexible).</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/Duet_Darth_Vader.mp3">
    </audio>
    <p>Darth Vader’s March</p>

    <h2>Traditional Music Selections</h2>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/ExtraSongs/reduced/BridalChorusWagner.mp3">
    </audio>
    <p>Wagner’s Bridal Chorus</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/ExtraSongs/reduced/Fanfare-Mouret.mp3">
    </audio>
    <p>Mouret’s Trumpet Fanfare</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/ExtraSongs/reduced/JesuJoyOfMansDesiringBach.mp3">
    </audio>
    <p>Bach’s Jesu Joy of Man’s Desiring</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/ExtraSongs/reduced/LaRejouissance-Handel.mp3">
    </audio>
    <p>Handel’s La Réjouissance</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/ExtraSongs/reduced/Prelide-Charpentier.mp3">
    </audio>
    <p>Charpentier’s Prelude</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/ExtraSongs/reduced/PrinceOfDenmarksMarth-Clarke.mp3">
    </audio>
    <p>Clarke’s Prince of Denmark March</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/ExtraSongs/reduced/TrumpetTune-Purcell.mp3">
    </audio>
    <p>Purcull’s Trumpet Tune</p>
    <audio controls>
      <source src="@/assets/audio/moms-website-songs/ExtraSongs/reduced/WeddingMarch-Mendeissohn.mp3">
    </audio>
    <p>Mendellsohn’s Wedding March</p>

    <p>Whatever your music and ensemble preferences, we will find a way to make it fit in an elegant and professional way.</p>

    <h1>FAQs</h1>
    <p><strong>1. How do the musicians dress?</strong></p>
    <p>The musicians dress in formal attire unless otherwise requested: men in tuxedos and women in black, or black and white.</p>
    <p><strong>2. What do you require at the venue? </strong></p>
    <p>Setup depends on the instrumentation and ensemble size.  In general, there should be an armless chair for each person in the ensemble, there needs to be enough open space to perform (for example, a quartet would need about an 8’x8′ area), and, if a room is not well lit, musicians will need to be able to use an electrical outlet to plug in lighting.</p>
    <p><strong>3. Will you play outdoors? </strong></p>
    <p>Yes, we have played many outdoor weddings in a variety of settings, however, due to the unpredictability of Michigan’s weather, we cannot guarantee outdoor performances.   It is important to plan ahead for a variety of weather possibilities. For instance: Musicians will require a shaded area (NOT under trees as they can drip sap) or temporary shelter (typically 10’x10′ or larger) if performing on a hot (85˚F+) sunny day.  Musicians will only perform in a dry protected place on a rainy day (shelter needs to be large enough to provide instrument case storage and protect from blowing rain).  Musicians will generally not be willing to expose their instruments (particularly stringed instruments) to precipitation, direct sunlight, temperatures in the performance area above 85˚F or below 65°F. Please keep in mind that our performers will cooperate and do what they can to accommodate your vision for your special occasion, but their instruments are both valuable and fragile and no musician will risk damaging their instrument.  As a general rule, if the conditions might be uncomfortable for your guests (and we mean the less tolerant ones), it is probably in a range that will damage stringed instruments.</p>
    <p><strong>4. What type of occasions will you play for?</strong></p>
    <p>We will play appropriate music for just about any occasion.  Our musicians are experienced in playing for wedding ceremonies, wedding receptions, celebrations and parties of all varieties (such as anniversaries, retirements, graduations, and birthdays), baby showers, bridal showers, sacred worship services, and we have provided music for memorial services and funerals.</p>
    <p><strong>5. Will you be at the wedding rehearsal?</strong></p>
    <p>It's not necessary for us to be at the rehearsal and it would include additional expense.  Our musicians are highly experienced in playing weddings and coordinating the music to the ceremony.  A representative from the ensemble will meet briefly with the officiant shortly before the ceremony begins to make sure everyone has the same outline and expectations.   If the ensemble is playing processional music, We will also require a pre-designated person who will give the ensemble a signal to start the processional and an ensemble member will want to meet to confirm plan with that person shortly before the ceremony.</p>

    <h2>Wedding Music Selection</h2>
    <p><strong>6. Should I have music before a wedding ceremony (prelude music)? </strong></p>
    <p>Highly recommended. Providing beautiful music for your guests as they arrive and wait for the event to begin creates a wonderful ambience. We suggest contracting your ensemble to start prelude music anywhere from 15-30 minutes before the start time of the ceremony.</p>
    <p><strong>7. How do I choose the prelude music? Am I required to select the whole program?</strong></p>
    <p>It is not necessary for you to have to choose all the prelude music. If you give us an idea of the type of ambience you wish to create using a few examples, we can use our expertise to select the remainder of the music. For example, you may want to include hymns or have only secular music, a festive upbeat atmosphere or more quiet, pensive one.</p>
    <p><strong>8. How many pieces should I choose for the processional?</strong></p>
    <p>It is common to have three separate pieces: one for the entrance of the mothers, one for the bridal party, and finally one for the entrance of the bride. This will be determined by the size of the bridal party and the venue. We will be happy to help you pick out music.</p>
    <p><strong>9. How will you know when to start the processional music? </strong></p>
    <p>We will need a signal either from a representative at the venue or an usher. We will seek out the pre-arranged person before the ceremony and confirm the plan.</p>
    <p><strong>10. Do we need to know how long the music will be in order to time our entrances to finish with the music? </strong></p>
    <p>No, you can leave that up to us.  The musicians are very skilled in ending the music at just the right time. We need only know how many people are processing to each piece of music.</p>
    <p><strong>11. Should I have music during my ceremony? </strong></p>
    <p>During a Christian  or non-denominational ceremony, there is often room for short pieces of music: after readings, during the lighting of the unity candle, presentation of roses to mothers, sign of peace, and of course communion or parts of the Mass. We recommend asking your officiant about these too, as there are certain pieces of music that may not be appropriate. During a Jewish ceremony, there is typically no music from string ensembles. Often a cantor, if present, will sing during the ceremony.</p>

    <h2>Recessional</h2>
    <p><strong>12. How many pieces do I choose for the recessional? </strong></p>
    <p>In general, there would be one piece performed during the exit of the entire wedding party, and one or two more for the exit of your guests.  The length of music necessary for a recessional can vary depending on the size of the venue and the number of guests attending.  </p>
    <p><strong>13. I am having a receiving line at the church. Can you perform for that, too?</strong></p>
    <p>Yes.</p>

    <h2>Planning</h2>
    <p><strong>14. When should we select the music? </strong></p>
    <p>Generally you should contact us before you meet with your officiant so that we may help you formulate questions to ask them about music. Often there are “dos” and “don’ts” regarding certain ceremonies. After you meet with your officiant, we can talk again and solidify the program. Our three-page printable timeline checklist has been very helpful to our clients.</p>
    <p><strong>15. There is a special piece of music I would like performed. Can you do that? </strong></p>
    <p>Yes.  Even if it is not in our repertiore, or available for string ensembles, we will be happy to write an arrangement for the quartet (there is a fee for making special music arrangements).</p>
    <p><strong>16. Do we need to meet in person before the event?</strong></p>
    <p>Not necessarily. All details can be addressed via email, phone conversations or video chats. </p>
  </div>
</template>

<script setup>
</script>

<style scoped>
.content-container {
  margin: 0 auto;
  max-width: 1200px;
  width: 90%;
  padding: 1rem;
}

.content-container img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.columns-container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.column {
  flex: 1;
}

.column ul {
  padding: 0;
}

.column h2 {
  margin-top: 0;
  text-align: center;
  text-decoration: underline;
}

.content-container audio {
  width: 100%;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .content-container {
    width: 95%;
  }

  .columns-container {
    flex-direction: column;
    gap: 20px;
  }

  .column {
    text-align: center;
  }

  .column ul {
    list-style-type: none;
    padding: 0;
  }
}
</style>
  