<template>
  <div className="content-container">
    <h1>Contact Us</h1>
    <p className="note">We would love to hear from you. Please get in touch with us if you have any questions about lessons or bookings and we will get back with you as soon as possible.</p>
    
    <form class="contact-form" @submit.prevent="handleSubmit" ref="form">
      <div class="form-group">
        <input type="email" v-model="fromEmail" placeholder="Your Email" required>
      </div>
      <div class="form-group">
        <input type="text" v-model="subject" placeholder="Subject" required>
      </div>
      <div class="form-group">
        <textarea v-model="message" placeholder="Your Message" required></textarea>
      </div>
      <div class="form-group ">
        <button type="submit" id="button-style">Send Message</button>
      </div>
    </form>

  </div>
</template>
  
<script setup>
import emailjs from 'emailjs-com'
import { useToast } from 'vue-toastification';
import { ref } from "vue";

const fromEmail = ref('')
const subject = ref('')
const message = ref('')
const toast = useToast();

function sendEmail() {
  emailjs.send("service_izwy97u", "template_iatsi0r", {
    from_name: fromEmail.value,
    subject: subject.value,
    message: message.value,
  }).then((response) => {
    console.log('SUCCESS!', response.status, response.text);
    toast.success("Message sent successfully!");
    fromEmail.value = '';
    subject.value = '';
    message.value = '';
  }, (err) => {
    console.error('EMAIL FAILED...', err);
    toast.error("Failed to send message.");
  });
}

const handleSubmit = () => {
  sendEmail(fromEmail.value, subject.value, message.value);
};
</script>

<style scoped>
.content-container {
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 1.8em;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.note {
  text-align: center;
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px; 
  box-sizing: border-box;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  box-sizing: border-box; 
}


textarea {
  resize: vertical;
  min-height: 120px;
}

#button-style {
  width: 100%;
  padding: 12px;
  font-size: 1.1em;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#button-style:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .content-container {
    width: 90%;
    padding: 15px;
  }

  h1 {
    font-size: 1.5em;
  }

  #button-style {
    font-size: 1em;
  }
}
</style>
  