import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; 
import emailjs from 'emailjs-com'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

emailjs.init("EnT3Qfe4jqEM4H-Fl")

createApp(App)
  .use(router)
  .use(Toast)
  .mount('#app');