<template>
  <nav class="navbar">
    <router-link to="/" class="navbar-logo">Affinity Strings</router-link>

    <!-- Hamburger Icon for Mobile -->
    <div class="hamburger" @click="toggleMenu">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Navigation Links -->
    <div :class="['nav-links', { 'nav-active': isMenuOpen }]">
      <router-link to="/" class="navbar-item" @click="closeMenu">Home</router-link>
      <router-link to="/lessons" class="navbar-item" @click="closeMenu">Lessons</router-link>
      <router-link to="/booking-inquiries" class="navbar-item" @click="closeMenu">Booking Inquiries</router-link>
      <router-link to="/contact-us" class="navbar-item" @click="closeMenu">Contact Us</router-link>
    </div>
  </nav>
</template>


<script setup>
import { ref } from 'vue';

const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const closeMenu = () => {
  isMenuOpen.value = false;
};
</script>


<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}

.navbar-logo, .navbar-item {
  text-decoration: none;
  color: black;
  font-family: "Crimson Text", serif;
}

.navbar-logo {
  font-size: 2rem;
}

.navbar-item {
  padding: 0.5em 1em;
  font-size: 1.2rem;
}

.navbar-item:hover {
  text-decoration: underline;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  height: 3px;
  width: 25px;
  background: black;
  margin: 4px;
  border-radius: 2px;
  transition: transform 0.3s ease;
}

/* Mobile View */
@media (max-width: 768px) {
  .nav-links {
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
    z-index: 10; /* Ensure menu is on top */
  }

  /* Show the hamburger menu icon on mobile */
  .hamburger {
    display: flex;
  }

  /* Show/hide navigation links when hamburger is clicked */
  .nav-links.nav-active {
    display: flex;
  }

  .navbar-item {
    font-size: 1.5rem;
    margin: 1em 0;
  }
}

/* Desktop */
@media (min-width: 769px) {
  .nav-links {
    display: flex;
    gap: 1em;
  }
}
</style>


